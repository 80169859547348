import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import { cloneDeep, find, findIndex, forEach, remove } from 'lodash-es'
import { getWorkBenchConfigApi, updateWorkBenchConfigApi } from '@/api/system'

export const useWorkBenchDBConfigStore = defineStore('dbConfig', () => {
  const dbConfig = ref({
    project: {
      name: '',
      icon: '',
      workbenchBackgroundImage: '',
      loginBackgroundImage: '',
    },
    categories: [] as Array<any>,
    apps: [] as Array<any>,
  })

  const cacheWorkBenchConfigStore = useWorkBenchCacheConfigStore()

  const apps = computed(() => dbConfig.value.apps)
  const categories = computed(() => dbConfig.value.categories)
  const project = computed(() => dbConfig.value.project)

  async function updateApp(ap: any) {
    const appIndex = findIndex(dbConfig.value.apps, app => (app.id === ap.id))

    if (appIndex !== -1) {
      dbConfig.value.apps[appIndex] = cloneDeep(ap)
      updateAppInCategory(ap)
      await updateCacheConfig()
    }

    function updateAppInCategory(app: any) {
      const { categoryId } = app

      const category = find(dbConfig.value.categories, ['id', categoryId])
      if (category && category.apps) {
        const categoryAppIndex = findIndex(category.apps, ['id', app.id])
        if (categoryAppIndex === -1)
          return
        category.apps[categoryAppIndex] = app
      }
    }
  }

  async function addApp(ap: any) {
    const nextApp = { ...ap, id: dayjs().valueOf() }
    dbConfig.value.apps.push(nextApp)
    putAppToCategory(nextApp)
    await updateCacheConfig()

    function putAppToCategory(app: any) {
      const category = find(dbConfig.value.categories, ['id', app.categoryId])
      if (category) {
        if (category.apps) {
          category.apps.push(app)
        }
        else {
          category.apps = [app]
        }
      }
    }
  }

  async function deleteApp(appId: number) {
    const index = findIndex(dbConfig.value.apps, app => app.id === appId)
    if (index !== -1) {
      deleteAppFromCategory(dbConfig.value.apps[index])
      dbConfig.value.apps.splice(index, 1)
      await updateCacheConfig()
      message.success('删除应用成功')
    }
    else {
      message.warning('删除失败')
    }

    function deleteAppFromCategory(app: any) {
      const { categoryId } = app
      const category = find(dbConfig.value.categories, ['id', categoryId])
      if (category) {
        remove(category.apps, ['id', app.id])
      }
    }
  }

  async function addCategory(category: any) {
    category.id = dayjs().valueOf()
    dbConfig.value.categories.push(category)
    await updateCacheConfig()
  }

  async function updateCategory(category: any) {
    const deepCategory = cloneDeep(category)
    const index = dbConfig.value.categories.findIndex(item => item.id === category.id)
    dbConfig.value.categories.splice(index, 1, deepCategory)
    await updateCacheConfig()
  }

  async function deleteCategory(categoryId: number) {
    const index = findIndex(dbConfig.value.categories, category => category.id === categoryId)

    if (index !== -1) {
      deleteAppsMapCategoryApps(dbConfig.value.categories[index].apps)
      remove(dbConfig.value.categories, ['id', categoryId])
      await updateCacheConfig()
      message.success('删除分类成功')
    }
    else {
      message.success('删除分类失败')
    }
  }

  function deleteAppsMapCategoryApps(apps: any[]) {
    forEach(apps, (app) => {
      remove(dbConfig.value.apps, ap => ap.id === app.id)
    })
  }

  async function setProject(proj: any) {
    dbConfig.value.project = cloneDeep(proj)
    await updateCacheConfig()
    cacheWorkBenchConfigStore.refreshWorkBenchConfig()
  }

  async function refreshDBConfig() {
    const { project, categories, apps } = await getWorkBenchConfigApi()
    dbConfig.value = {
      project: project || {},
      categories: categories || [],
      apps: apps || [],
    }
  }

  async function updateCacheConfig() {
    await updateWorkBenchConfigApi(dbConfig.value)
    await refreshDBConfig()
  }

  return { apps, categories, project, refreshDBConfig, deleteApp, addApp, updateApp, setProject, addCategory, updateCategory, deleteCategory }
})
